import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ProfileContext } from '../../contexts/profileContext';
import { getProfileDataForExportAll } from '../../service';
import { exportAllData } from '../../utils/utils';
import Button from '../common/button';
import AdminManager from '../teamMemberManager';
import LanguageDropdown from './languageDropdown';
import Notifications from './notification';
import Search from './search';
import { toast } from 'react-toastify';

export default function Header() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [profile] = useContext(ProfileContext);
  const [searchParams] = useSearchParams();
  const {
    masterOrganization: {
      languages = [],
      translationPrefix,
      customConfigurations: { colors } = {}
    } = {}
  } = profile;

  const [isExportingData, setIsExportingData] = useState(false);
  const [langOptions, setLangOptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState();

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    navigate('/login');
  };

  const handleChange = async (e) => {
    setSelectedLanguage(e);

    try {
      localStorage.setItem('lang', e.value);
    } catch (error) {
      console.log('LS Unavailable');
    }

    i18n.changeLanguage(e.value);
  };

  useEffect(() => {
    if (languages.length > 0) {
      const langOptions = languages.map((lang) => {
        return { label: lang?.toUpperCase(), value: lang };
      });
      setLangOptions(langOptions);
      selectLanguage(languages);
    }
  }, [languages]);

  const selectLanguage = (masterLanguages) => {
    let selectedLang;
    const seachParamLang = searchParams.get('lang');
    if (masterLanguages.includes(seachParamLang)) {
      selectedLang = seachParamLang;
    }
    try {
      if (selectedLang) {
        localStorage.setItem('lang', selectedLang);
      } else {
        const locallyStoredLang = localStorage.getItem('lang');
        if (masterLanguages.includes(locallyStoredLang)) selectedLang = locallyStoredLang;
        else selectedLang = masterLanguages?.[0] ?? 'en';
      }

      setSelectedLanguage({
        label: selectedLang.toUpperCase(),
        value: selectedLang
      });
    } catch (error) {
      console.log('LS Unavailable');
      selectedLang = masterLanguages?.[0] ?? 'en';
    } finally {
      i18n.changeLanguage(selectedLang);
    }
  };

  const handleExportAllData = async () => {
    try {
      setIsExportingData(true);
      const { data: exportItems } = await getProfileDataForExportAll(i18n.language);
      exportAllData(exportItems, t, translationPrefix);
    } catch (error) {
      toast.error('Failed to export data');
      console.error('Failed to export data: ', error);
    } finally {
      setIsExportingData(false);
    }
  };

  return (
    <>
      <div
        className={'flex-shrink-0 border-b-4 shadow-sm border-opacity-70'}
        style={{
          borderColor: colors?.primary
        }}>
        <header className={'px-6'}>
          <div className={'flex justify-between items-center  py-2'}>
            {/* left */}
            <div className="flex items-center gap-4">
              <AdminManager />
              <Button.ButtonOutlined
                loading={isExportingData}
                spinnerColor="#000"
                className="ml-6 px-6 py-2 font-bold"
                onClick={handleExportAllData}>
                {t(`exportAllData`)}
              </Button.ButtonOutlined>
              {/* <h1 className="font-bold text-2xl">System Admin</h1> */}
            </div>

            {/* right */}
            <div className={'flex items-center'}>
              <Notifications />
              <Search />
              {langOptions.length > 1 && (
                <span className="ml-4 mt-4">
                  <LanguageDropdown
                    value={selectedLanguage}
                    options={langOptions}
                    onChange={handleChange}
                  />
                </span>
              )}
              <Button.ButtonFilled className="ml-6 px-6 py-2 font-bold" onClick={handleLogout}>
                {t(`logout`)}
              </Button.ButtonFilled>
            </div>
          </div>
        </header>
      </div>
    </>
  );
}
