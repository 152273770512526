import axios from 'axios';
import * as XLSX from 'xlsx';

import { toast } from 'react-toastify';
import { getTranslation } from '.';
import { permissions } from '../data/permissions';
import userRoles from '../data/roles';

const uninterceptedAxiosInstance = axios.create();

export const getPermisssionFlagsForProfile = (profile) => {
  const createOrganization =
    profile.role === 'systemAdmin' ||
    (profile.permissions &&
      profile.permissions.some((permission) => permission._id === permissions.CREATE_ORGANIZATION));

  const manageSubSystemAdmins =
    profile.role === 'systemAdmin' ||
    (profile.permissions &&
      profile.permissions.some(
        (permission) => permission._id === permissions.MANAGE_SYSTEM_SUBADMINS
      ));

  const manageOrganizationSubAdmins =
    profile.role === 'systemAdmin' ||
    (profile.permissions &&
      profile.permissions.some(
        (permission) => permission._id === permissions.MANAGE_ORGANIZATION_SUBADMINS
      ));

  const manageOrganizationProfiles =
    profile.role === 'systemAdmin' ||
    (profile.permissions &&
      profile.permissions.some(
        (permission) => permission._id === permissions.MANAGE_ORGANIZATION_PROFILES
      ));

  return {
    createOrganization,
    manageSubSystemAdmins,
    manageOrganizationSubAdmins,
    manageOrganizationProfiles
  };
};

export const getErrorMessage = (error, message) => {
  if (typeof error?.response?.data === 'string') {
    return error.response.data;
  } else if (typeof error?.response?.data?.message === 'string') {
    return error.response.data.message;
  } else {
    return message;
  }
};

// Function to check if an element has a specific parent
export const hasSomeParentOrSelf = (el, selector) => {
  while (el) {
    if (el.matches(selector)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

export const hasPermission = (profile, targetRole, targetPermission) => {
  return (
    profile &&
    (profile.role === targetRole ||
      (Array.isArray(profile.permissions) &&
        profile.permissions.some((permission) => permission._id === targetPermission)))
  );
};

export const checkVideoUrlValidity = async (videoURL) => {
  try {
    const response = await uninterceptedAxiosInstance.head(`${videoURL}?_=${new Date().getTime()}`);
    // If the request completes successfully, the resource exists
    return response.status >= 200 && response.status < 300;
  } catch (error) {
    return false;
  }
};

export const getVideoUrl = (videoData) => {
  const isUpdated = videoData?.updatedAt ? Date.parse(videoData.updatedAt) : '';
  const currentTime = new Date();
  const parsedTime = Date.parse(currentTime);
  return `${process.env.REACT_APP_AWS_URL}profile-videos/${videoData?.videoURL}?date=${parsedTime}`;
};

// converted array to string
const getFieldValue = (fieldValue) => {
  return Array.isArray(fieldValue) ? fieldValue.join(', ') : fieldValue;
};

export const exportData = (headers, allData) => {
  try {
    const headerKeys = headers.map((header) => header.key);
    const wb = XLSX.utils.book_new();
    // Initialize the "Profiles" sheet with headers
    const profilesWorksheet = XLSX.utils.json_to_sheet([], {
      header: headers.map((header) => header.name)
    });

    // Process and add allData to the "Profiles" sheet, ensuring data matches the headers
    allData.forEach((dataItem) => {
      // Create a new object that includes all keys from headerKeys, using a default value for missing keys
      const filteredDataItem = headerKeys.reduce((acc, key) => {
        // Use the header key for the key, defaulting to a specific value if the key doesn't exist in the dataItem
        acc[key] = dataItem.hasOwnProperty(key) ? getFieldValue(dataItem[key]) : ''; // Default value can be 'N/A', null, or ''
        return acc;
      }, {});

      // Directly adding filtered data to the profilesWorksheet
      XLSX.utils.sheet_add_json(profilesWorksheet, [filteredDataItem], {
        skipHeader: true,
        origin: -1
      });
    });

    // Append the populated "Profiles" worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, profilesWorksheet, 'Profiles');

    // Write the workbook to a file
    XLSX.writeFile(wb, 'Profiles-Data.xlsx');
  } catch (error) {
    console.error('Failed to export data', error);
    toast.error('Failed to export data.');
  }
};

export const exportAllData = (exportItems, t, translationPrefix) => {
  try {
    const wb = XLSX.utils.book_new(); // Create a new workbook

    // Loop through each exportItem to create a sheet for each
    Object.entries(exportItems).forEach(([name, { profiles, headers }]) => {
      const headerKeys = headers.map((header) => header.key);

      const sheetHeader = [
        'Email',
        ...headers.map(
          (header) => getTranslation(t, `form.fields.${header.key}`, translationPrefix)?.label
        )
      ].filter(Boolean);

      // Initialize sheet with headers
      const sheet = XLSX.utils.json_to_sheet([], {
        header: sheetHeader
      });

      // Process and add profiles to the sheet, ensuring data matches the headers
      profiles.forEach((dataItem) => {
        const filteredDataItem = headerKeys.reduce((acc, key) => {
          // Use the header key for the key, defaulting to a specific value if the key doesn't exist in the dataItem
          acc[key] = dataItem.hasOwnProperty(key) ? getFieldValue(dataItem[key]) : ''; // Default value can be 'N/A', null, or ''
          return acc;
        }, {});

        // Directly adding filtered data to the sheet
        XLSX.utils.sheet_add_json(sheet, [filteredDataItem], {
          skipHeader: true,
          origin: -1 // Append at the bottom
        });
      });

      // Append the populated sheet to the workbook
      XLSX.utils.book_append_sheet(wb, sheet, name);
    });

    // Write the workbook to a file
    XLSX.writeFile(wb, 'Exported-Data.xlsx');
  } catch (error) {
    console.error('Failed to export data: ', error);
    toast.error('Failed to export data.');
  }
};

export function hexToRgba(hex, opacity) {
  if (!hex) return `rgba(12, 34, 56, 1)`;
  // Remove the # symbol from the hex code
  hex = hex.replace('#', '');

  // Convert the hex code to RGB values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Convert the opacity percentage to a decimal value
  const alpha = parseInt(opacity) / 100;

  // Create the RGBA string
  const rgba = `rgba(${r}, ${g}, ${b}, ${alpha})`;

  return rgba;
}

export const getProfileUrl = (profileType, profileId) => {
  if (userRoles.COMPETITOR.includes(profileType)) {
    return `https://www.mission-future.com/competiteur-profile/${profileId}?adminProfileView=true`;
  } else if (profileType === userRoles.CH_CHAMPION || profileType === userRoles.CH_AMBASSADOR) {
    return `https://www.mission-future.com/ch/champion-profile/${profileId}?adminProfileView=true`;
  } else {
    return `https://www.mission-future.com/champion-profile/${profileId}?adminProfileView=true`;
  }
};
