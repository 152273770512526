import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  }
);

const uninterceptedAxiosInstance = axios.create();
const adminAxios = axios.create();

adminAxios.interceptors.request.use((config) => {
  const token = localStorage.getItem('adminToken');
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

adminAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    console.log('ERRROROR', error);
    if (error.response?.status === 401) {
      localStorage.removeItem('adminToken');
      const pathName = window.location.pathname;
      const locations = ['/login', '/ch/login', '/'];
      if (!locations.includes(pathName)) {
        window.location.href = '/';
      }
    }
    // return new Error(error);
    return Promise.reject(error);
  }
);

const fetchTranslations = () => {
  return axios.get(BASE_URL + '/public/translations/admin-panel');
};

const fetchTranslationsProfileCreation = () => {
  return axios.get(BASE_URL + '/public/translations/profile-creation');
};

const login = (body) => {
  return axios.post(BASE_URL + '/auth/login', body);
};
const verifyLink = (body) => {
  return axios.post(BASE_URL + '/auth/magic', body, {
    headers: { 'Access-Control-Allow-Origin': '*' }
  });
};

const adminMagicVerify = (body) => {
  return axios.post(BASE_URL + '/auth/admin-magic', body);
};

//Update Profile Data
const updateProfileData = (body) => {
  return axios.post(BASE_URL + '/users/profile', body);
};
//Get Uplaoding File Link and Final Name
const getUploadingFileData = (body) => {
  return axios.post(BASE_URL + '/uploader/upload', body);
};
//Upload File to the Link
const uploadFileToAWS = (url, file, contentType) => {
  return uninterceptedAxiosInstance.put(url, file, {
    headers: {
      'Content-type': contentType
    }
  });
};
//Upload Video to the link
const uploadVideo = (url, videoFile, setUplaodProgress, fileType) => {
  return uninterceptedAxiosInstance.put(url, videoFile, {
    headers: {
      'Content-type': fileType
    },
    onUploadProgress: (progressEvent) => {
      setUplaodProgress((progressEvent.loaded / videoFile.size) * 100);
    }
  });
};
//Post Video Link for the Recording
const postVideoLink = (body) => {
  return adminAxios.post(BASE_URL + '/admin/video', body);
};

// delete video based on the userid and videoid
const deleteVideo = (query) => {
  return adminAxios.delete(BASE_URL + '/admin/video?' + query);
};
//Post User Image
const postUserImageLink = (body) => {
  return adminAxios.post(BASE_URL + '/admin/user-image', body);
};
//Post Strenghts and Roles
const postStrengthsAndRoles = (body) => {
  return axios.post(BASE_URL + '/users/strengths-role', body);
};

const getUserProfile = () => {
  return axios.get(BASE_URL + '/users/profile');
};

const getAdminProfile = () => {
  return adminAxios.get(BASE_URL + '/admin');
};

const getPublicProfile = (id) => {
  return axios.get(BASE_URL + `/public/profile/${id}`);
};

const requestValidation = () => {
  return axios.get(BASE_URL + `/users/request-validation`);
};

const getRegions = (organization = '650bd89479ba3ae92524955b') => {
  return axios.get(BASE_URL + `/public/regions?organization=` + organization);
};

const getProfessions = (organization = '650bd89479ba3ae92524955b') => {
  return axios.get(BASE_URL + `/public/professions`, { params: { organization } });
};
const getRoles = (organization = '650bd89479ba3ae92524955b') => {
  return axios.get(BASE_URL + `/public/roles?organization=` + organization);
};

const getStrengths = (organization = '650bd89479ba3ae92524955b') => {
  return axios.get(BASE_URL + `/public/strengths?organization=` + organization);
};

const registerTeacher = (body) => {
  let postURL = 'https://develop.psyfiers.ch/core/security/register?path=mission-future-eleves';
  return axios.post(postURL, body, {
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  });
};

const contactFormSubmit = (body) => {
  let postURL = BASE_URL + '/public/contact-form';
  return axios.post(postURL, body, {
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  });
};

const generatePDF = (body) => {
  return axios.post(BASE_URL + '/public/generate-pdf', body);
};

const videoSupportContact = (body) => {
  return axios.post(BASE_URL + `/generic/video-support`, body);
};

const updatePDFStatus = (body) => {
  return axios.get(BASE_URL + `/users/generated-pdf`, body);
};

const searchChampions = (body) => {
  return axios.post(BASE_URL + `/public/search-champions`, body);
};

const linkStrengthToVideo = (body) => {
  return axios.post(BASE_URL + `/users/video-strength`, body);
};

const adminLogin = (body) => {
  return axios.post(BASE_URL + `/auth/admin`, body);
};

const getAllUsers = (role) => {
  return adminAxios.get(BASE_URL + `/admin/users`, { params: { role } });
};

const getUserToken = (body) => {
  return adminAxios.post(BASE_URL + `/admin/user-token`, body);
};

const approveUser = (id) => {
  return adminAxios.get(BASE_URL + `/admin/approve/${id}`);
};
const deleteUser = (id) => {
  return adminAxios.delete(BASE_URL + `/admin/remove/${id}`);
};

const loginStudent = (id) => {
  let postURL = `https://develop.psyfiers.ch/core/tan/login?tan=${id}`;
  return axios.get(postURL, {
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  });
};

const getWorldCloud = (body) => {
  let postURL = `https://develop.psyfiers.ch/core/wordcloud/create/words?color=F1F1F1`;
  return uninterceptedAxiosInstance.post(postURL, body, {
    responseType: 'blob',
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  });
};

const getClassData = (id) => {
  let postURL = `https://develop.psyfiers.ch/core/project/read/all?id=${id}`;
  return axios.get(postURL, {
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  });
};

const addNewStudent = (body) => {
  return axios.post(BASE_URL + `/student/profile`, body);
};
const getStudentProfile = (params) => {
  return axios.get(BASE_URL + `/student/profile`, { params });
};
const getLookaLikeChampions = (params) => {
  return axios.get(BASE_URL + `/student/look-alike-champions`, { params });
};

const getProjectViewData = (params) => {
  return axios.get(BASE_URL + `/student/class-data`, { params });
};

const getClassImages = (params) => {
  return axios.get(BASE_URL + `/student/class-images`, { params });
};

const getProfileTypes = () => {
  return adminAxios.get(BASE_URL + `/admin/profile-type`);
};

const getPermissions = (body) => {
  return adminAxios.get(BASE_URL + `/admin/permissions`);
};

const getOrganization = (id) => {
  return adminAxios.get(BASE_URL + `/admin/organization`, { params: { id } });
};

const createOrganizationAPI = (body) => {
  return adminAxios.post(BASE_URL + `/admin/organization`, body);
};

const updateOrganization = (body) => {
  return adminAxios.patch(BASE_URL + `/admin/organization`, body);
};

const getAllOrganizations = () => {
  return adminAxios.get(BASE_URL + `/admin/organization`);
};

const deleteOrganization = (body) => {
  return adminAxios.delete(BASE_URL + `/admin/organization`, { data: body });
};

const createSubAdmin = (body) => {
  return adminAxios.post(BASE_URL + `/admin/sub-system-admin`, body);
};

const deleteSubAdmin = (body) => {
  return adminAxios.delete(BASE_URL + `/admin/sub-system-admin`, { data: body });
};

const updateSubAdmin = (body) => {
  return adminAxios.patch(BASE_URL + `/admin/sub-system-admin`, body);
};

const getSubAdmins = () => {
  return adminAxios.get(BASE_URL + `/admin/sub-system-admin`);
};

const getOrganizationSubAdmins = (params) => {
  return adminAxios.get(BASE_URL + `/admin/sub-organization-admin`, { params });
};

const createOrganizationSubAdmin = (body) => {
  return adminAxios.post(BASE_URL + `/admin/sub-organization-admin`, body);
};

const updateOrganizationSubAdmin = (body) => {
  return adminAxios.patch(BASE_URL + `/admin/sub-organization-admin`, body);
};

const deleteOrganizationSubAdmin = (body) => {
  return adminAxios.delete(BASE_URL + `/admin/sub-organization-admin`, { data: body });
};

const getOrganizationProfiles = (params) => {
  return adminAxios.get(BASE_URL + `/admin/organization-profiles`, { params });
};

const createOrganizationProfile = (body) => {
  return adminAxios.post(BASE_URL + `/admin/organization-profiles`, body);
};

const updateOrganizationProfile = (body) => {
  return adminAxios.patch(BASE_URL + `/admin/organization-profiles`, body);
};

const approveProfile = (body) => {
  return adminAxios.post(BASE_URL + `/admin/validate-profile`, body);
};

const unapproveProfile = (body) => {
  return adminAxios.post(BASE_URL + `/admin/disapprove-profile`, body);
};

const rejectProfile = (body) => {
  return adminAxios.patch(BASE_URL + `/admin/reject-profile`, body);
};

const disableProfiles = (body) => {
  return adminAxios.post(BASE_URL + `/admin/disable-profiles`, body);
};

const deleteProfiles = (body) => {
  return adminAxios.post(BASE_URL + `/admin/delete-profiles`, body);
};

const moveProfiles = (body) => {
  return adminAxios.post(BASE_URL + `/admin/move-profiles`, body);
};

const updateAdminProfile = (body) => {
  return adminAxios.patch(BASE_URL + `/admin/update-profile`, body);
};

const downloadSinglePDFProfiles = (body) => {
  return adminAxios.post(BASE_URL + `/admin/bulk-pdfs`, body);
};

const downloadAllPDFProfilesAsOne = (body) => {
  return adminAxios.patch(BASE_URL + `/admin/`, body);
};

const getUserProfileForAdmin = (id) => {
  return adminAxios.get(BASE_URL + `/admin/user-profile`, { params: { id } });
};

const removeNotification = (body) => {
  return adminAxios.patch(BASE_URL + `/admin/remove-notification`, body);
};

const getBulkProfileUploadData = (groupId) => {
  return adminAxios.get(BASE_URL + `/admin/bulk-excel-for-profiles`, {
    params: { id: groupId }
  });
};

const addBulkProfileData = (body) => {
  return adminAxios.post(BASE_URL + `/admin/bulk-excel-for-profiles`, body, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

const getProfessionalAssociation = () => {
  return axios.get(BASE_URL + `/public/professional-association`);
};

const getFunction = (organization = '650bd7c379ba3ae92524955a') => {
  return axios.get(BASE_URL + `/public/functions`, { params: { organization } });
};

const getSpecialJobTitles = (organization = '650bd7c379ba3ae92524955a') => {
  return axios.get(BASE_URL + `/public/special-job-titles`, { params: { organization } });
};

const getVocationalSchool = () => {
  return axios.get(BASE_URL + `/public/vocational-school`);
};

const getHeadersForUserDataExport = (masterOrganization = '650bd89479ba3ae92524955b') => {
  return adminAxios.get(BASE_URL + `/admin/get-headers-for-user-data-export`, {
    params: { organization: masterOrganization }
  });
};

const getUserDataForExport = (body) => {
  return adminAxios.post(BASE_URL + `/admin/export-user-data`, body);
};

const getBundledPdfsURL = (body) => {
  return adminAxios.post(BASE_URL + `/admin/generate-bundle-pdfs`, body);
};

const getCombinedPdfURL = (body) => {
  return adminAxios.post(BASE_URL + `/admin/generate-combined-pdf`, body);
};

const updateVideoStrength = (body) => {
  return adminAxios.put(BASE_URL + `/admin/video-strength/`, body);
};

const updateVideoRole = (body) => {
  return adminAxios.put(BASE_URL + `/admin/video-role/`, body);
};

const addNote = (body) => {
  return adminAxios.post(BASE_URL + `/admin/notes`, body);
};

const updateNote = (id, body) => {
  return adminAxios.patch(BASE_URL + `/admin/notes/${id}`, body);
};

const deleteNote = (id) => {
  return adminAxios.delete(BASE_URL + `/admin/notes/${id}`);
};

const getAllProfilesDataForExport = (lang = 'en') => {
  return adminAxios.get(BASE_URL + `/admin/organization-data-export?lang=${lang}`);
};

const addGroupColumn = (body) => {
  return adminAxios.post(BASE_URL + `/admin/group-column`, body);
};

const updateGroupColumn = (id, body) => {
  return adminAxios.put(BASE_URL + `/admin/group-column/${id}`, { ...body, type: 'column-name' });
};

const deleteGroupColumn = (id) => {
  return adminAxios.delete(BASE_URL + `/admin/group-column/${id}`);
};

const disableGroupColumn = (id) => {
  return adminAxios.put(BASE_URL + `/admin/group-column/${id}`);
};

const addGroup = (body) => {
  return adminAxios.post(BASE_URL + `/admin/groups`, body);
};

const getGroups = () => {
  return adminAxios.get(BASE_URL + `/admin/groups`);
};

const disableGroup = (id) => {
  return adminAxios.patch(BASE_URL + `/admin/groups/${id}`);
};

const deleteGroup = (id) => {
  return adminAxios.delete(BASE_URL + `/admin/groups/${id}`);
};

const getGroupNotes = (id) => {
  return adminAxios.get(BASE_URL + `/admin/notes/group/${id}`);
};

const sendEmailNotification = (id, body) => {
  return adminAxios.post(BASE_URL + `/admin/groups/${id}/email-notification`, body);
};

const getAllTeamMembers = (id) => {
  return adminAxios.get(BASE_URL + `/admin/groups/${id}/team-members`);
};

const addTeamMember = (id, body) => {
  return adminAxios.post(BASE_URL + `/admin/groups/${id}/team-members`, body);
};

const removeTeamMember = (id, teamMemberId) => {
  return adminAxios.delete(BASE_URL + `/admin/groups/${id}/team-members/${teamMemberId}`);
};

const getAllGroupProfiles = (id, page) => {
  return adminAxios.get(BASE_URL + `/admin/groups/${id}/profiles`);
};

const approveGroupProfiles = (id, profileIds, profileEmails) => {
  return adminAxios.put(BASE_URL + `/admin/groups/${id}/profiles/approve`, {
    profileIds,
    profileEmails
  });
};

const refuseGroupProfiles = (id, body) => {
  return adminAxios.put(BASE_URL + `/admin/groups/${id}/profiles/refuse`, body);
};

const disableGroupProfiles = (id, profileIds, profileEmails) => {
  return adminAxios.put(BASE_URL + `/admin/groups/${id}/profiles/disable`, {
    profileIds,
    profileEmails
  });
};

const deleteGroupProfiles = (id, profileIds, profileEmails) => {
  return adminAxios.put(BASE_URL + `/admin/groups/${id}/profiles/delete`, {
    profileIds,
    profileEmails
  });
};

const createGroupProfiles = (id, profiles) => {
  return adminAxios.post(BASE_URL + `/admin/groups/${id}/profiles/create`, { profiles });
};

const moveGroupProfiles = (id, profiles, moveToGroupId) => {
  return adminAxios.put(BASE_URL + `/admin/groups/${id}/profiles/move`, {
    profiles,
    moveToGroupId
  });
};

const moveGroup = (body) => {
  return adminAxios.post(BASE_URL + `/admin/move-group`, body);
};

const getProfileDetails = (profileId) => {
  return adminAxios.get(BASE_URL + `/admin/profile/${profileId}`);
};

const getProfileData = (profileId) => {
  return adminAxios.get(BASE_URL + `/admin/profile/${profileId}/data`);
};

const getCompleteGroupDetails = (groupId) => {
  return adminAxios.get(BASE_URL + `/admin/groups/${groupId}/details`);
};

const getNotifications = (type) => {
  return adminAxios.get(BASE_URL + `/admin/notifications?type=${type}`);
};

const globalSearch = (query) => {
  return adminAxios.get(BASE_URL + `/admin/search/?query=${query}`);
};

const getActiveGroups = () => {
  return adminAxios.get(BASE_URL + `/admin/active-groups`);
};

const getActiveColumns = () => {
  return adminAxios.get(BASE_URL + `/admin/active-columns`);
};

const getProfileDataForExport = (type, id, language = 'en') => {
  return adminAxios.get(BASE_URL + `/admin/export-data?${type}=${id}&language=${language}`);
};

const getProfileDataForExportAll = (language = 'en') => {
  return adminAxios.get(BASE_URL + `/admin/export-all-data?language=${language}`);
};

const updateGroupName = (id, body) => {
  return adminAxios.put(BASE_URL + `/admin/groups/${id}`, { ...body });
};

export {
  fetchTranslations,
  fetchTranslationsProfileCreation,
  login,
  verifyLink,
  updateProfileData,
  getUploadingFileData,
  uploadFileToAWS,
  uploadVideo,
  postVideoLink,
  postUserImageLink,
  postStrengthsAndRoles,
  getUserProfile,
  getPublicProfile,
  requestValidation,
  getRegions,
  getProfessions,
  registerTeacher,
  contactFormSubmit,
  videoSupportContact,
  getRoles,
  getStrengths,
  generatePDF,
  updatePDFStatus,
  searchChampions,
  linkStrengthToVideo,
  adminLogin,
  getAllUsers,
  getUserToken,
  approveUser,
  deleteUser,
  loginStudent,
  addNewStudent,
  getStudentProfile,
  getWorldCloud,
  getClassData,
  getProjectViewData,
  getClassImages,
  getLookaLikeChampions,
  adminMagicVerify,
  getAdminProfile,
  updateAdminProfile,
  getProfileTypes,
  getPermissions,
  createOrganizationAPI,
  updateOrganization,
  getOrganization,
  getAllOrganizations,
  deleteOrganization,
  getSubAdmins,
  createSubAdmin,
  deleteSubAdmin,
  updateSubAdmin,
  getOrganizationSubAdmins,
  createOrganizationSubAdmin,
  updateOrganizationSubAdmin,
  deleteOrganizationSubAdmin,
  getOrganizationProfiles,
  createOrganizationProfile,
  updateOrganizationProfile,
  approveProfile,
  rejectProfile,
  disableProfiles,
  deleteProfiles,
  moveProfiles,
  downloadSinglePDFProfiles,
  downloadAllPDFProfilesAsOne,
  getUserProfileForAdmin,
  removeNotification,
  getBulkProfileUploadData,
  addBulkProfileData,
  getFunction,
  getVocationalSchool,
  getSpecialJobTitles,
  getProfessionalAssociation,
  getHeadersForUserDataExport,
  getUserDataForExport,
  deleteVideo,
  getBundledPdfsURL,
  getCombinedPdfURL,
  unapproveProfile,
  updateVideoStrength,
  addNote,
  updateNote,
  deleteNote,
  getAllProfilesDataForExport,
  addGroupColumn,
  deleteGroupColumn,
  disableGroupColumn,
  addGroup,
  getGroups,
  disableGroup,
  deleteGroup,
  getGroupNotes,
  sendEmailNotification,
  getAllTeamMembers,
  removeTeamMember,
  addTeamMember,
  getAllGroupProfiles,
  approveGroupProfiles,
  refuseGroupProfiles,
  moveGroupProfiles,
  createGroupProfiles,
  disableGroupProfiles,
  deleteGroupProfiles,
  getProfileDetails,
  getProfileData,
  getCompleteGroupDetails,
  getNotifications,
  globalSearch,
  getActiveGroups,
  getProfileDataForExport,
  updateVideoRole,
  updateGroupColumn,
  updateGroupName,
  getActiveColumns,
  moveGroup,
  getProfileDataForExportAll
};
